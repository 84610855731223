.cmp-options-body {
  .cmp-option-item{
    width: 200px;
  }
}

.cmp-options-footer {
  display: flex;
  justify-content: space-between;

  .left {
  }

  .right {
    display: flex;

    div {
      padding-left: 5px;
    }
  }
}

@primary-color: #1d7fe3;@link-color: #004586;