.res-sum-container {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
}

.res-sum-card {
  background: white;
  padding: 15px;
  margin: 15px;
  height: 150px;
  width: 300px;
  border: gray solid 1px;

  font-size: 1rem;

  /*no selectable text*/
  -webkit-touch-callout: none; /* iOS Safari */
  -webkit-user-select: none; /* Safari */
  -khtml-user-select: none; /* Konqueror HTML */
  -moz-user-select: none; /* Old versions of Firefox */
  -ms-user-select: none; /* Internet Explorer/Edge */
  user-select: none;
  /* Non-prefixed version, currently
                                   supported by Chrome, Edge, Opera and Firefox */
}

.res-sum-body {
  height: 100%;
  display: flex;
  justify-content: space-between;
}

.res-sum-body-col-1 {
  height: 100%;
  display: flex;
  justify-content: space-between;
  flex-direction: column;

}

.res-sum-body-col-1-profile {
  display: flex;
  flex-direction: row;
}

.res-sum-body-col-1-profile-image {
  display: flex;
  flex-direction: column;
  justify-content: center;
}

.res-sum-body-col-1-profile-name {
  display: flex;
  flex-direction: column;
  justify-content: center;
  padding-left: 5px;
  font-size: 0.8rem;
  font-weight: bold;

  .res-sum-person:hover {
    cursor: pointer;
  }
}

.res-sum-col-1-status {
  padding-top: 8px;
  font-size: 1rem;
}

.res-sum-col-1-status-description {
  font-size: 0.6rem;
}


.res-sum-body-col-2 {
  text-align: center;
}

.res-sum-col-2-room-name {
  font-size: 1.7rem;
  font-weight: bold;
  text-align: center;
}

.res-sum-col-2-room-label {
  font-size: 0.6rem;
  font-weight: bold;
}

@primary-color: #1d7fe3;@link-color: #004586;