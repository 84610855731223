.dashboard-practitioner {
  display: flex;
  justify-content: space-between;

  .dashboard-pract-residents-card {
    width: 100%;

  }

  .dashboard-pract-alerts-sidebar {
    flex-basis: 500px;
    height: 100%;
  }
}
@primary-color: #1d7fe3;@link-color: #004586;