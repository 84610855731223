.person-overview-page {
  display: flex;
  justify-content: space-between;

  .person-overview {
    width: 100%;
  }

  .person-overview-alerts-sidebar {
    flex-basis: 500px;
    height: 100%;
  }

  .latest-active-alert {
    text-align: center;
    color: white;
    font-size: 36px;
    padding: 40px 120px;
    //width: 276px;
    //height: 95px;
  }
}

@primary-color: #1d7fe3;@link-color: #004586;