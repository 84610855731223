.legend-group-container {
  display: flex;
  border: rgb(106, 187, 242) solid 1px;
  border-radius: 5px;
  background: rgb(249, 249, 249);
  height: 32px;
  margin: 5px;
  padding-left: 5px;

  -webkit-touch-callout: none; /* iOS Safari */
  -webkit-user-select: none; /* Safari */
  -khtml-user-select: none; /* Konqueror HTML */
  -moz-user-select: none; /* Old versions of Firefox */
  -ms-user-select: none; /* Internet Explorer/Edge */
  user-select: none;

  .group-title-container {
    display: flex;
    flex-direction: column;
    justify-content: center;
    height: 100%;

    .group-title {
      display: flex;
      justify-content: center;
      font-size: 15px;
      line-height: 100%;
    }

    .group-subtitle {
      display: flex;
      justify-content: center;
      font-size: 9px;
      line-height: 100%;
    }
  }

  .group-items {
    display: flex;
    justify-content: center;
    flex-direction: column;

    .legend-item-container {
      display: flex;
      justify-content: center;
      margin-left: 5px;
      margin-right: 5px;

      .legend-item:hover {
        cursor: pointer;
      }

      .legend-item-selected {
        font-weight: bold;
      }

      .legend-item {
        display: flex;
        justify-content: center;
        margin-left: 5px;
        margin-right: 5px;



        .legend-item-icon-container {
          display: flex;
          flex-direction: column;
          justify-content: center;

          .legend-item-icon {
            border: gray solid 1px;
            border-radius: 5px;
            width: 10px;
            height: 10px;
            margin-right: 3px;
          }
        }

        .legend-item-name {
          font-size: 12px;
        }
      }
    }
  }

  .group-close:hover {
    cursor: pointer;
  }

  .group-close {
    display: flex;
    flex-direction: column;
    justify-content: center;
    font-size: 15px;
    padding: 5px;
    background: rgb(233, 233, 233);
    border-radius: 0px 5px 5px 0px;
    width: 21px;
    text-align: center;
  }


  .legend-restore-colour {
    display: flex;
    flex-direction: column;
    justify-content: center;
    padding-left: 5px;
    padding-right: 5px;
  }

  .legend-restore-colour:hover {
    cursor: pointer;
  }
}

@primary-color: #1d7fe3;@link-color: #004586;